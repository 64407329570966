import React from 'react';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './TeamSelector.css';

const TeamSelector = (props) => {
  const { activeTeam, callback, teams, page} = props;
	return (
    <ComponentWrapper className={`c-TeamSelector`}>
      <select className="team-select" onChange={e => {e.persist(); callback(e)}}>
        <option defaultValue value="all">{activeTeam ? `All ${page}` : `Team ${page}`}</option>
        {teams && teams.map((team, i) =>
        <option value={team.team_id} key={team.name + team.team_id}>{team.name}</option>
        )}
      </select>  
    </ComponentWrapper>
	); 
};

TeamSelector.propTypes = {
  activeTeam: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.string.isRequired
  ]),
  teams: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.array.isRequired
  ]),
  callback: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired

}

export default TeamSelector;