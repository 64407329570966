import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  h2 {
    text-transform: none;
    margin: 2rem 0 1rem;
  }
  .MatchSummaryCard-single {
    background: var(--background);
    border: 1px solid var(--border);
    box-shadow: 0px .3rem .6rem rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    color: (--text);
    display: grid;    
    font-size: 1.4rem;
    font-weight: bold;
    grid-template-columns: calc(100% * (150 / 1024)) calc(100% * (100 / 1024)) calc(100% * (220 / 1024)) calc(100% * (250 / 1024)) auto;
    max-width: 102.4rem;
    text-align: center;
    width: 100%;
    &:not(:first-of-type) {
      margin-top: 2rem;
    }
    .header {
      border-bottom: 1px solid var(--border);
      padding: 0.8rem 0;
      text-align: left;
      &.center {
        text-align: center;
      }
      &:first-child {
        padding-left: 1rem;
      }
    }
    img {
      max-width: 4rem;
    }
    .data {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 1.5rem 0;
      &.players {
        align-items: flex-start;
      }
      &.links {
        justify-content: center;
      }
    }
    .mobile-vs {
      display: none;
      margin: 0 1rem;
    }
    .mobile-score {
      display: none;
    }
    @media only screen and (max-width: 768px) {
      .header {
        &.top-players, &.summary, &.links {
          display: none;
        }
      }
      .data {
        padding: 0;
        &.top-players, &.summary, &.summary, img {
          display: none;
        }
        &.teams {
          align-items: center;
          flex-direction: row;
          justify-content: flex-start;
          margin-left: 1rem;
          .mobile-vs {
            display: block;
          }
        }
        &.score {
          flex-direction: row;
          justify-content: center;
          p {
            margin: 0 0.25rem;
          }
          .mobile-score {
            display: block;
          }
        }
        &.links {
          grid-area: b;
          padding-bottom: 1rem;
        }
      }
      .mobile-vs {
        display: block;
        margin: 0 2rem;
      }
      grid-template: 
        "a a"
        "a a"
        "b b";
      grid-auto-columns: 1fr;
    }
    .teams {
      align-items: flex-start;
      margin-left: 2.5rem;
    }
    .team {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      padding: 1rem 0;
      img {
        margin-right: 2.5rem;
      }
    }
    .player {
      align-items: center;
      display: flex;
      justify-content: center;
      font-size: 1.2rem;
      font-weight: 400;
      text-transform: uppercase;
      .player-img-container {
        background-color: var(--secondary-background);
        border-radius: 50%;
        height: 100%;
        margin-right: 1.5rem;
        max-width: 4rem;
        max-height: 4rem;
        overflow: hidden;
      }
      .team-img {
        bottom: -1rem;
        width: 2rem;
        position: relative;
        left: -2.2rem;
      }
      p {
        position: relative;
        right: 1.5rem;
      }
    }
    .top-players {
      padding-left: 2rem;
    }
    
    .summary {
      font-size: 1.2rem;
      font-weight: 400;
      text-align: left;
      span {
        max-height: 7.3rem;
        overflow-y: scroll;
        vertical-align: text-top;
        box-shadow: inset 0px -73px 22px -58px var(--background);
      }
    }
    .link-button {
      background-color: var(--primary);
      color: var(--background);
      font-size: 1.2rem;
      font-weight: 400;
      margin: 0.25rem 0;
      max-width: 16rem;
      padding: 0.75rem 1.8rem;
      text-decoration: none;
    }
    .top-god-name{
      padding-left:1.5rem;
    }
  }
`;