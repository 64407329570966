import React from 'react';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './StandingsCard.css';
import { Link, navigate } from 'gatsby';

const StandingsCard = (props) => {
  const { data, season, header } = props;
	return (
    <ComponentWrapper className="c-StandingsCard">
      <div className="standings-header-container">
        <h3>{header?`${header} `:''}Standings</h3>
        <Link to="/standings">All Standings »</Link>
      </div>
      <table>
        <thead>
          <tr>
            <th colSpan="2">{season}</th>
            <th>Matches</th>
            <th>Wins</th>
            <th>Losses</th>
            <th>Win %</th>
          </tr>
        </thead>
        <tbody className="splr">
          {data && data.map((item, i)=>
            <tr key={item.team_shortname} className={`team${(props.team && (props.team.toLowerCase() === item.team_shortname.toLowerCase())) ? ' active' : ''}`} onClick={() => navigate(`/teams/${item.team_shortname.toLowerCase()}`)}>
              <td>{i + 1}</td>
              <td><img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${item.team_shortname}-70x70.png`} alt={item.team_shortname} /> <div>{item.team_shortname}</div></td>
              <td>{item.matches}</td>
              <td>{item.wins}</td>
              <td>{item.losses}</td>
              <td>{item.win_percent}</td>
            </tr>
          )}
        </tbody>
      </table>
    </ComponentWrapper>
	); 
};

StandingsCard.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.array.isRequired
  ]),
  season: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.string.isRequired
  ]),
  team: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ])
}
const SlimStandingsCard = (props) => {
  const { data, season, header } = props;
	return (
    <ComponentWrapper className="c-SlimStandingsCard">
      <div className="standings-header-container">
        <h3>{header?`${header} `:''}Standings</h3>
        <Link to="/standings">All Standings »</Link>
      </div>
      <table>
        <thead>
          <tr>
            <th colSpan="2">{season}</th>
            <th>Matches</th>
            <th>Win %</th>
          </tr>
        </thead>
        <tbody className="splr">
          {data && data.map((item, i)=>
            <tr key={item.team_shortname} className={`team${(props.team && (props.team.toLowerCase() === item.team_shortname.toLowerCase())) ? ' active' : ''}`} onClick={() => navigate(`/teams/${item.team_shortname.toLowerCase()}`)}>
              <td>{i + 1}</td>
              <td><img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${item.team_shortname}-70x70.png`} alt={item.team_shortname} /> <div>{item.team_shortname}</div></td>
              <td>{item.matches}</td>
              <td>{item.win_percent}</td>
            </tr>
          )}
        </tbody>
      </table>
    </ComponentWrapper>
	); 
};

SlimStandingsCard.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.array.isRequired
  ]),
  season: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.string.isRequired
  ]),
  team: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ])
}

export { StandingsCard, SlimStandingsCard };
