import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './MatchSummaryCard.css';

const showSummaryOrTopGods= (match) =>{
  if(match.summary){
    return <div className="data summary splr"><span>{match.summary}</span></div>;
  }
  else{
    return (
    <div className="data players top-players ">
      <div className="player">
          <div className="player-img-container"><img src={match.top_1_god_name && `https://webcdn.hirezstudios.com/smite/god-icons/${match.top_1_god_name.toLowerCase()}.jpg`} /></div>
        <p className="top-god-name">{match.top_1_god_name}</p>
      </div>
      <div className="player">
        <div className="player-img-container"><img src={match.top_2_god_name && `https://webcdn.hirezstudios.com/smite/god-icons/${match.top_2_god_name.toLowerCase()}.jpg`} /></div>
            <p className="top-god-name">{match.top_2_god_name}</p>
      </div>
    </div>)
  }
};

const MatchSummaryCard = (props) => {
  const { data,phase } = props;

  
	return (
    <ComponentWrapper className="c-MatchSummaryCard">
      <h2>{new Date(data.date*1000).toLocaleDateString(navigator.language,{ month: 'long', day: 'numeric', year: 'numeric'})}</h2>
    {data && data.matches.filter((day)=>{
        if (day.phase == phase) {
          return true;
        }
        else false;
      }).map(match =>
      <div className="MatchSummaryCard-single" key={match.match_id}>
      <div className="header">FINAL</div>
      <div className="header center">Set Score</div>
      <div className="header top-players">Top Players</div>
      <div className="header summary">{match.summary?"Match Summary":match.top_1_god_name?"Top Gods":""}</div>
      <div className="header links"></div>
      <div className="data teams">
        <div className="team">
          <img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${match.team_1_shortname}-70x70.png`} />
          <p className={`${(match.winning_team && match.winning_team !== match.team_1_shortname) ? 'loss' : ''}`}>{match.team_1_shortname}</p>
        </div>
        <p className="mobile-vs">VS</p>
        <div className="team">
          <img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${match.team_2_shortname}-70x70.png`} />
          <p className={`${(match.winning_team && match.winning_team !== match.team_2_shortname) ? 'loss' : ''}`}>{match.team_2_shortname}</p>
        </div>
      </div>
      <div className="data score splr">
        <p className="mobile-score">{match.team_1_shortname}</p>
        <p>{match.team_1_score}</p>
        <p className="mobile-score"> - </p>
        <p className="mobile-score">{match.team_2_shortname}</p>
        <p>{match.team_2_score}</p>
      </div>
      <div className="data players top-players splr">
        <div className="player">
            <div className="player-img-container"><img src={match.top_1_name && `https://webcdn.hirezstudios.com/smite-esports/players/${match.top_1_name.toLowerCase()}.png`} /></div>
            {match.top_1_name !== null &&
              <img className="team-img" src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${match.top_1_team === match.team_1_name ? match.team_1_shortname : match.team_2_shortname}.png`} />
            }
          <p>{match.top_1_name}</p>
        </div>
        <div className="player">
          <div className="player-img-container"><img src={match.top_2_name && `https://webcdn.hirezstudios.com/smite-esports/players/${match.top_2_name.toLowerCase()}.png`} /></div>
            {match.top_2_name !== null &&
              <img className="team-img" src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${match.top_2_team === match.team_2_name ? match.team_2_shortname : match.team_1_shortname}.png`} />
            }
              <p>{match.top_2_name}</p>
        </div>
      </div>
      {/* Check if match summary is present, and if not show top gods instead*/}
      {showSummaryOrTopGods(match)}
      
      <div className="data links">
        {match.playlist_url && 
        <a href={match.playlist_url} target="_blank" className="link-button">
          <i className="icon-play" /><span>Videos</span>
        </a>
        }
        <Link to={`/matches/${match.match_id}`} className="link-button">
          <i className="icon-calendar" /><span>Post-Game Stats</span>
        </Link>  
      </div>
      </div>
      )}
    </ComponentWrapper>
	); 
};

MatchSummaryCard.propTypes = {
  data: PropTypes.object.isRequired
}

export default MatchSummaryCard;