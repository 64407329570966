import React, { Component } from 'react';
import withContext from 'store/withContext';
import {StandingsCard} from 'components/Global/StandingsCard';
import MatchSummaryCard from 'components/Scores/MatchSummaryCard';
import TeamSelector from 'components/Global/TeamSelector';

import PageWrapper from '../styles/scores.css';

class Scores extends Component {

  constructor(props) {
    super(props);

    this.state = {
      phase: null,
      current: true,
      startDate: null,
      endDate: null,
      selectedDate: null,
      activeSchedule: null,
      activeTeam: undefined,
      selectedWeek: null,
      nextArrowStatus: 'disabled',
      maxPhaseWeeks: 0
    }
  }

  // d=data, t=target, s=start, e=end, m=middle, node=what value you're searching for in the data
  binarySearch = (d, t, s, e, node) => {
    const m = Math.floor((s + e)/2);
    
    if (t == d[m][node]) {
      return d[m];
    }
    if ((e - 1) === s || m === 0) {
      let found = {};
      if (Math.abs(d[s][node] - t) > Math.abs(d[e][node] - t)) {
        Object.keys(d[e]).forEach(key => {
          found[key] = d[e][key];
        })
        found.index = e;
      }
      else {
        Object.keys(d[s]).forEach(key => {
          found[key] = d[s][key];
        })
        found.index = s;
      }
      return found;
    } 
    if (t > d[m][node]) return this.binarySearch(d,t,m,e,node);
    if (t < d[m][node]) return this.binarySearch(d,t,s,m,node);
  }

  setToday = () => {
    if (this.state.activeSchedule.schedule.length > 0) {
      let closestDate = this.binarySearch(this.state.activeSchedule.schedule, this.props.context.now, 0, this.state.activeSchedule.schedule.length - 1, 'date');
      // this.setPhase(closestDate.phase, parseInt(closestDate.week), closestDate.date, closestDate.index);
      this.setPhase(closestDate)
    }
  }

  setPhase = (phaseData) => {
    if (phaseData && typeof phaseData !== 'string') {
      var phase = phaseData.title;
      var selectedWeek = parseInt(phaseData.week);
      var selectedDate = phaseData.date;
      var dateIndex = phaseData.index;
    }

    let bracket;
    if (!phase && this.state.activeSchedule.phases.length > 0) {
      phase = this.state.activeSchedule.phases[this.state.activeSchedule.phases.length - 1].title;
      bracket = this.state.activeSchedule.phases[this.state.activeSchedule.phases.length - 1].is_bracket === 0 ? false : true;
    }
    let start, end;
    this.state.activeSchedule.phases.forEach(phaseObject => {
      if (phaseObject.title === phase) {
        start = phaseObject.start_date,
        end = phaseObject.end_date;
      }
    });

    let nextArrowStatus = 'disabled';
    if (!selectedDate && !dateIndex && !selectedWeek) {
      console.log(this.state.activeSchedule.schedule)
      for (let i = 0; i < this.state.activeSchedule.schedule.length - 1; i++) {
        if (this.state.activeSchedule.schedule[i].phase === phase) {
          //Only update the selected Date and week if the matches have a winning team set.
          for(let match in this.state.activeSchedule.schedule[i].matches){
            if(this.state.activeSchedule.schedule[i].matches[match].winning_team){
              selectedDate = this.state.activeSchedule.schedule[i].date;
              selectedWeek = parseInt(this.state.activeSchedule.schedule[i].week);
              dateIndex = i;
            }
          }
        }
        //Additional filter rule for Season 8 Phase 1 Group stage
        if (phase=='Phase 1 - Group B' && this.state.activeSchedule.schedule[i].phase ==='Phase 1 - Group A') {
          selectedDate = this.state.activeSchedule.schedule[i].date;
          selectedWeek = parseInt(this.state.activeSchedule.schedule[i].week);
          dateIndex = i;
        }
        //Additional filter rule for Season 8 Phase 3 Group stage
        if (phase=='Phase 3 - Group B' && this.state.activeSchedule.schedule[i].phase ==='Phase 3 - Group A') {
          selectedDate = this.state.activeSchedule.schedule[i].date;
          selectedWeek = parseInt(this.state.activeSchedule.schedule[i].week);
          dateIndex = i;
        }
      }

      bracket = this.state.activeSchedule.phases.find(item => item.title === phase).is_bracket === 0 ? false : true;
      if (bracket) {
        this.getBracket(this.state.activeSchedule.phases.find(item => item.title === phase).id);
      }

      const getPhaseWeeks = (weekCounter, match) => {
        if (match.phase === phase && parseInt(match.week) > weekCounter) {
          return (weekCounter + 1);
        }
        else return weekCounter + 0;
      }

      const phaseWeeks = this.state.activeSchedule.schedule.reduce(getPhaseWeeks, 1);
      if (phaseWeeks <= 1 || selectedWeek >= phaseWeeks) {
        nextArrowStatus = 'disabled';
      }
      this.setState({
        phase: phase,
        startDate: start,
        endDate: end,
        selectedDate: selectedDate,
        dateIndex: dateIndex,
        selectedWeek: selectedWeek,
        nextArrowStatus: nextArrowStatus,
        showBracket: bracket,
        phaseWeeks: phaseWeeks
      });
    }
    else {
      this.setState({
        selectedDate: selectedDate,
        dateIndex: dateIndex,
        selectedWeek: selectedWeek
      });
    }
  }

  getBracket = (id) => {
    fetch(`${this.props.context.baseUrl}/bracket/${this.props.context.eventId}/${id}`)
    .then(response => response.json())
    .then(response => {
      this.setState({
        bracketData: response.bracket_html,
        showBracket: true
      })
    });
  }

  disableNextArrow = (futureSelectedWeek) => {
    let currentPhaseSchedule = this.state.activeSchedule.schedule.filter(this.filterPhase);
    let furthestWeek = null
    for (let i = 0; i < this.state.activeSchedule.schedule.length - 1; i++) {
        if(furthestWeek){break}
        for(let match in this.state.activeSchedule.schedule[i].matches){
          if(!this.state.activeSchedule.schedule[i].matches[match].winning_team){
            //Update furthestWeek if we find a match without a winner set
            furthestWeek = parseInt(this.state.activeSchedule.schedule[i].week);
            break
          }
        }
      }
    //If we didn't find a match without a winner set earlier, set it to the end of the phase
    if(!furthestWeek){
      furthestWeek = parseInt(currentPhaseSchedule[currentPhaseSchedule.length - 1].week);
    }
    (futureSelectedWeek === furthestWeek) ? this.setState({nextArrowStatus: 'disabled'}) : this.setState({nextArrowStatus: 'enabled'});
  }

  prev = () => {
    this.disableNextArrow(this.state.selectedWeek - 1);
    this.setState({
      selectedWeek: this.state.selectedWeek - 1
      // dateIndex: this.state.dateIndex - 1,
      // phase: this.state.activeSchedule.schedule[this.state.dateIndex - 1].phase
    });
  }

  next = () => {
    this.disableNextArrow(this.state.selectedWeek + 1);
    this.setState({
      selectedWeek: this.state.selectedWeek + 1
      // dateIndex: this.state.dateIndex + 1,
      // phase: this.state.activeSchedule.schedule[this.state.dateIndex + 1].phase
    });
  }

  filterPast = (day) => {
    if (day.date < this.props.context.now) {
      return true;
    }
    else false;
  }

  filterWeek = (day) => {
    if (parseInt(day.week) === this.state.selectedWeek) {
      return true;
    }
    else return false;
  }

  filterPhase = (day) => {
    //Additional filter rule for Season 8 Phase 1 Group stage
    if (this.state.phase==='Phase 1 - Group B' && day.phase === 'Phase 1 - Group A') {
      return true;
    }
    //Additional filter rule for Season 8 Phase 3 Group stage
    if (this.state.phase==='Phase 3 - Group B' && day.phase === 'Phase 3 - Group A') {
      return true;
    }
    //Standard filter rule
    if (day.phase === this.state.phase) {
      return true;
    }
    else return false;
  }

  getTeamSchedule = (e, teamId) => {
    if (e) {
      fetch(`${this.props.context.baseUrl}/schedule/${this.props.context.eventId}${teamId ? teamId : (e.target.value !== 'all') ? `/${e.target.value}` : ''}`)
      .then(response => response.json())
      .then(response => {
        let activeTeam = (e.target.value === 'all') ? 'null' : this.props.context.teams.find(team => team.team_id === parseInt(response.requested_team));
        this.setState(() => ({
          activeSchedule: {
            ...response,
            schedule: response.schedule.filter(this.filterPast)
          },
          activeTeam: activeTeam
        }));
      });
    }
    //this is done on load if team props are passed via link
    else {
      fetch(`${this.props.context.baseUrl}/schedule/${this.props.context.eventId}/${teamId}`)
      .then(response => response.json())
      .then(response => {
        let activeTeam = this.props.context.teams.find(team => team.team_id === parseInt(teamId));
        this.setState(() => ({
          activeSchedule: {
            ...response,
            schedule: response.schedule.filter(this.filterPast)
          },
          activeTeam: activeTeam
        }));
      });
    }
  }
  updateStandings = (phase_id) => {
    console.log(phase_id)
    fetch(`${this.props.context.baseUrl}/standings/${this.props.context.eventId}${phase_id ? '/' + phase_id : ''}`)
    .then(response => response.json())
    .then(response => {
      console.log(response)
      this.setState({standings: response});
    });
  }
  componentDidMount() {
    if (this.props.location.state && this.props.location.state.hasOwnProperty('team')) {
      this.getTeamSchedule(null, this.props.location.state.team)
    }
    if (this.props.context.schedule && !this.state.selectedDate) {
      this.setState(() => ({
        activeSchedule: {
          ...this.props.context.schedule,
          schedule: this.props.context.schedule.schedule
        }
      }));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.context.schedule !== this.props.context.schedule) {
      this.setState(() => ({
        activeSchedule: {
          ...this.props.context.schedule,
          schedule: this.props.context.schedule.schedule
        }
      }));
    }
    if (prevState.activeSchedule !== this.state.activeSchedule) {
      this.setPhase();
      if (!this.state.selectedDate) {
        this.setToday();
      }
    }
    if (prevState.activeSchedule !== null && (prevState.activeSchedule.requested_team !== this.state.activeSchedule.requested_team)) {
      this.setToday();
    }
  }

  render() {
    return (
      <PageWrapper>
        {(this.state.activeSchedule !== null && this.state.activeSchedule.schedule.length > 0) ?
        <div className="center-wrapper">
          <div className="page-controls">
            <h1>{process.env.GATSBY_LEAGUE_HEADER_LONG} Scores</h1>
            <TeamSelector 
              activeTeam={this.state.activeTeam}
              teams={this.props.context.teams && this.props.context.teams}
              callback={this.getTeamSchedule}
              page={'Scores'} 
              />
            {/* <div className="current-selector">
              <div className={`current ${this.state.current ? 'selected' : ''}`} onClick={() => this.setState({current: true})}>Current Schedule</div>
              <div className={`all ${!this.state.current ? 'selected' : ''}`} onClick={() => this.setState({current: false})}>All Dates</div>
            </div> */}
            <div className="phase-selector">
              {(this.state.activeSchedule.hasOwnProperty('phases')) && this.props.context.schedule.phases.map((phase, i) => 
              <div className={`phase ${this.state.phase === phase.title ? 'selected' : ''}`} onClick={(event) => {this.setPhase(phase);  this.updateStandings(phase.id);}} key={phase.title + i}>{phase.title}</div>
              )}
            </div>
          </div>
          <p className="date-range">{this.state.startDate && new Date(this.state.startDate*1000).toLocaleDateString(navigator.language,{month: 'long', day: 'numeric', year: 'numeric'})} - {this.state.endDate && new Date(this.state.endDate*1000).toLocaleDateString(navigator.language,{month: 'long', day: 'numeric', year: 'numeric'})}</p>
         {(this.state.activeSchedule && !this.state.showBracket) && 
         <div className="date-selector">
            <div className={`arrow-container prev${(this.state.selectedWeek === 1 || this.state.phaseWeeks <= 1) ? ' disabled' : ''}`} onClick={this.prev}><div className="arrow" /></div>
            <p>Scores for Week {this.state.selectedWeek}</p>
            <div className={`arrow-container next ${this.state.nextArrowStatus}`} onClick={this.next}><div className="arrow" /></div>
          </div>
          }
          <div className="component-wrapper">
            <div className={`${this.state.showBracket?"bracket-left":"left"}`}>
              <div className="scores-wrapper">
                {((this.state.activeSchedule && this.state.dateIndex >= 0) && !this.state.showBracket && (this.state.dateIndex < this.state.activeSchedule.schedule.length)) && this.state.activeSchedule.schedule.filter(this.filterWeek).filter(this.filterPhase).filter(this.filterPast).map((match,i) => 
                  <MatchSummaryCard data={match} key={match.date + match.week + i} phase={this.state.phase}/>
                )}
                
                {(this.state.activeSchedule.schedule && this.state.activeSchedule.schedule.filter(this.filterWeek).filter(this.filterPhase).filter(this.filterPast).length === 0 && !this.state.showBracket) &&
                <h2>No Games This Week</h2>
                }
              </div>
              {this.state.showBracket && this.state.bracketData &&
                <div className="bracket-wrapper">
                  <h2>Bracket</h2>
                  <div className="bracket-container" dangerouslySetInnerHTML={{ __html: this.state.bracketData }} />
                </div>
              }
            </div>
            {!this.state.showBracket &&<StandingsCard
              header={this.state.phase}
              season={process.env.GATSBY_LEAGUE_HEADER}
              data={this.state.standings||this.props.context.standings}
            />}
          </div>
        </div>
        :
        <div className="center-wrapper">
          <div className="page-controls">
            <h1>{process.env.GATSBY_LEAGUE_HEADER_LONG} Scores</h1>
            <TeamSelector 
              activeTeam={this.state.activeTeam}
              teams={this.props.context.teams && this.props.context.teams}
              callback={this.getTeamSchedule}
              page={'Scores'} 
              />
          </div>
          <div className="component-wrapper">
            <div className="scores-wrapper">
              <h2>No Scores Data Yet</h2>
            </div>
            <StandingsCard
              header={this.state.phase}
              season={process.env.GATSBY_LEAGUE_HEADER}
              data={this.state.standings||this.props.context.standings}
            />
          </div>
        </div>
        }
      </PageWrapper>
    );
  }
}

export default withContext(Scores);
