import styled from 'styled-components';

const PageWrapper = styled.div`
  padding: 5.3rem 0;
  .left {
    width: 100%;
    max-width: 102.4rem;
  }
  .bracket-left {
    max-width: 146rem;
  }
  @media only screen and (max-width: 768px) {
    padding-top: 0;
  }
  .c-TeamSelector {
    @media only screen and (max-width: 768px) {
      margin-top: 3rem;
    }
  }
  .center-wrapper {
    background-color: var(--background);
    box-shadow: 0px 0.3rem 0.6rem rgba(0,0,0,0.16);
    color: var(--text);
    margin: 0 auto;
    max-width: 146.6rem;
    padding: 5rem;
  }
  .page-controls {
    margin: 0 auto;
    max-width: 149rem;
    width: 100%;
  }
  .date-range {
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
  }
  .date-selector {
    align-items: center;
    display: flex;
    p {
      font-size: 1.8rem;
      font-weight: bold;
      min-width: 20rem;
      padding: 0 1.5rem;
      text-align: center;
    }
  }
  .arrow-container {
    align-items: center;
    background: var(--secondary-background);
    cursor: pointer;
    display: flex;
    padding: 0.55rem 0.7rem;
    &.next {
      transform: rotate(180deg);
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .arrow {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent; 
    border-right: 8px solid var(--text);
    height: 0;
    pointer-events: none;
    width: 0;
  }
  /* .current-selector {
    display: flex;
    div {
      background-color: #F1F1F1; 
      color: #707070;
      cursor: pointer;
      font-weight: bold;
      padding: 1rem 1.5rem;
      text-transform: uppercase;
    }
    .selected {
      color: var(--background);
      background-color: var(--text);
    }
  }
  .current{
    margin-right: 0.5rem;
  } */
  .phase-selector {
    display: flex;
    margin: 4rem 0;
    .phase {
      color: var(--text);
      cursor: pointer;
      font-size: 1.4rem;
      font-weight: bold;
      margin-right: 4rem;
      position: relative;
      text-transform: uppercase;
      &.selected:after {
        background-color: var(--primary);
        bottom: -1rem;
        content: '';
        height: 3px;
        left: 0;
        position: absolute;
        width: 100%;
        @media only screen and (max-width: 768px) {
          bottom: 0.4rem;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      overflow-x:scroll;
      min-height: 4rem;
    }
  }
  h1 {
    display: inline-block;
    font-size: 2.4rem;
    text-transform: uppercase;
  }
  .component-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 146rem;
  }
  .scores-wrapper {
    margin-top: 3.5rem;
    max-width: 102.4rem;
    width: 100%;
  }
  .c-MatchSummaryCard:not(:first-of-type) {
    margin-top: 1rem;
  }
  .c-MatchSummaryCard:first-of-type {
    margin-top: -2rem;
  }
  .c-StandingsCard {
    margin-top: 4.1rem;
    max-width: 41.4rem;
    img {
      margin: 0 1rem;
    }
  }
  .bracket-wrapper {
    background: var(--background);
    border: .1rem solid var(--border);
    box-shadow: 0px .3rem .6rem rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    margin-top: 2rem;
    padding: 2rem;
    min-height: 52rem;
    .eventBracket {
      overflow: auto;
      overflow-y: hidden;
      display: flex;
      margin: auto;
      padding-bottom: 50px;
      min-height: 52rem;
    }

    .bracketBox {
      display: flex;
      height: auto;
      width: min-content;
      position: relative;
      max-width: 100vw;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      float: left;
      position: relative;
      width: auto;
      top: 50px;
      margin-left: -2px;
      white-space: nowrap;
    }

    .bracketHeader {
      font-family: var(--font-heading);
      font-size: 1.2rem;
      padding-left: 2.4rem;
      text-transform: uppercase;
    }

    /*Hide bracketlines for first column of matches*/
    .container:first-child .matchWrapper::before,
    .container:first-child .match::after {
      display: none;
    }

    .container:first-child .matchWrapper::before,
    .container:first-child .match::after {
      display: none;
    }

    .container:first-child .matchWrapper::before,
    .container:first-child .match::after {
      display: none;
    }

    .container:last-child .matchWrapper::after {
      display: none;
    }

    .bracketList {
      height: 100%;
      min-height: 100%;
      margin-bottom: 0;
      border-bottom: 0;
      padding-bottom: 2em;
      display: flex;
      flex-direction: column;
      flex-flow: row wrap;
      justify-content: center;
      transition: padding 0.2s ease-in-out, margin 0.2s ease-in-out;
    }

    .matchWrapper {
      display: flex;
      flex: 0 1 auto;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      padding: 0.5em 1.5em;
    }

    .matchWrapper:nth-child(2n + 1)::after {
      top: 50%;
      border-top: 1px solid var(--secondary-text);
      -webkit-transform: translateX(-1px);
      transform: translateX(-1px);
    }

    .matchWrapper::after {
      width: 1.4em;
    }

    .matchWrapper::after {
      position: absolute;
      right: 0;
      content: "";
      display: block;
      height: 30%;
      border-right: 1px solid var(--secondary-text);
      -webkit-transform: translateX(-1px);
      transform: translateX(-1px);
    }

    .matchWrapper::after {
      transition: width 0.2s linear;
    }

    .matchWrapper:nth-child(2n)::after {
      bottom: 50%;
      border-bottom: 1px solid var(--secondary-text);
    }

    .matchWrapper::before {
      position: absolute;
      left: 0;
      z-index: 1;
      content: "";
      display: block;
      width: 1.5em;
      height: 10%;
      border-left: 1px solid var(--secondary-text);
      bottom: 50%;
      border-bottom: 1px solid var(--secondary-text);
    }

    .hidematch.matchWrapper::after {
      position: absolute;
      left: 0;
      z-index: 1;
      content: "";
      display: block;
      width: 1em;
      height: 10%;
      border-left: none;
      top: 50%;
      border-top: none;
      width: 1.5em;
      -webkit-transform: translate(0, -1px);
      transform: translate(0, -1px);
    }

    .match {
      background-color: #f1f1f1;
      box-sizing: border-box;
      color: #2a2a2a;
      border: 1px solid var(--secondary-text);
      box-shadow: 0 2px 0 0 #e5e5e5;
      outline: none;
      transition: padding 0.2s ease-in-out, border 0.2s linear;
      div:first-of-type {
        align-items: center;
        display: flex;
        font-size: 1.2rem;
        border-bottom: .1rem solid #2a2a2a;
        height: 2.5rem;
        padding-left: 1rem;
        text-transform: uppercase;
      }
      .bracketParticipant {
        align-items: center;
        display: flex;
        height: 4rem;
        padding-left: 1rem;
        justify-content: flex-start;
      }
    }

    .match::after {
      position: absolute;
      left: 0;
      z-index: 1;
      content: "";
      display: block;
      width: 1em;
      height: 10%;
      border-left: 1px solid var(--secondary-text);
      top: 50%;
      border-top: 1px solid var(--secondary-text);
      width: 1.5em;
      -webkit-transform: translate(0, -1px);
      transform: translate(0, -1px);
    }

    .bracketParticipant {
      display: flex;
      width: 225px;
      position: relative;
      &.winner {
        background: #C6FFCA;
      }
    }

    .bracketParticipantLogo {
      background-image: url("https://webcdn.hirezstudios.com/smite-esports/dev/teams/dark/SSG.png");
      background-size: 25px 25px;
      border: none !important;
      margin-right: 5px;
      width: 25px;
      height: 25px;
      padding-left: initial !important;
      position: relative;
      align-items: center;
      justify-content: center;
    }

    .bracketParticipantName {
      color: #2a2a2a;
      font-size: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-grow: 1;
      max-width: 150px;
      text-align: left;
      line-height: 25px;
      padding-left: 5px;
      text-transform: uppercase;
      font-weight: bold;
    }

    .bracketParticipantScore {
      color: #2a2a2a;
      font-size: 12px;
      font-weight: bold;
      position: absolute;
      margin-right: 10px;
      right: 0px;
      max-width: 50px;
    }

    .thirdPlaceMatch {
      box-sizing: border-box;
      
      display: inline;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .matchThirdPlace {
      background-color: #f1f1f1;
      box-sizing: border-box;
      color: #2a2a2a;
      -webkit-transition: padding 0.2s ease-in-out,border 0.2s linear;
      transition: padding 0.2s ease-in-out,border 0.2s linear;
      span {
        display: block;
        border: none;
        box-sizing: border-box;
        width: 100%;
        align-items: center;
        display: flex;
        font-size: 1.2rem;
        border-bottom: .1rem solid #2a2a2a;
        height: 2.5rem;
        padding-left: 1rem;
        text-transform: uppercase;
      }
      br {
        display: none;
      }
      .bracketParticipant {
        align-items: center;
        display: flex;
        height: 4rem;
        padding-left: 1rem;
        justify-content: flex-start;
      }
      .bracketParticipant {
        display: flex;
        width: 225px;
        margin-bottom: 5px;
        position: relative;
      }

      .bracketParticipantLogo {
        background-image: url("https://webcdn.hirezstudios.com/smite-esports/dev/teams/dark/SSG.png");
        background-size: 25px 25px;
        border: none !important;
        margin-right: 5px;
        width: 25px;
        height: 25px;
        padding-left: initial !important;
        position: relative;
        align-items: center;
        justify-content: center;
      }

      .bracketParticipantName {
        color: #2a2a2a;
        font-size: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        flex-grow: 1;
        max-width: 150px;
        text-align: left;
        line-height: 25px;
        padding-left: 5px;
        text-transform: uppercase;
        font-weight: bold;
      }

      .bracketParticipantScore {
        color: #2a2a2a;
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        margin-right: 10px;
        right: 0px;
        max-width: 50px;
      }
    }

    /*Additional bracket match box style*/
    .lower_ro32_cu .matchWrapper::after,
    .lower_ro16_cu .matchWrapper::after,
    .lower_quarterfinals_cu .matchWrapper::after,
    .lower_semifinals_cu .matchWrapper::after,
    .round_of_2_column .matchWrapper::after,
    .grand_finals_column .matchWrapper::after {
      border-bottom-right-radius: 0em;
      border-top-right-radius: 0em;
      border-right: none;
    }

    .lower_ro32_cu::after,
    .lower_ro32_cu .match::after,
    .lower_ro16_cu::after,
    .lower_ro32_cu .match::after,
    .lower_quarterfinals_cu::after,
    .lower_quarterfinals_cu .match::after,
    .lower_semifinals_cu::after,
    .lower_semifinals_cu .match::after,
    .round_of_2_column::after,
    .round_of_2_column .match::after .grand_finals_column::after,
    .grand_finals_column .match::after {
      border-top-right-radius: 0em;
      border-right: none;
    }

    .lower_ro16::before,
    .lower_ro16 .matchWrapper::before,
    .lower_quarterfinals::before,
    .lower_quarterfinals .matchWrapper::before,
    .lower_semifinals::before,
    .lower_semifinals .matchWrapper::before,
    .lower_finals::before,
    .lower_finals .matchWrapper::before,
    .grand_finals_column::before,
    .grand_finals_column .matchWrapper::before,
    .grand_finals_reset_column::before,
    .grand_finals_reset_column .matchWrapper::before {
      border-left: none;
      border-bottom-left-radius: 0em;
    }

    .lower_ro16 .match::before,
    .lower_ro16 .match::after,
    .lower_quarterfinals .match::before,
    .lower_quarterfinals .match::after,
    .lower_semifinals .match::before,
    .lower_semifinals .match::after,
    .lower_finals .match::before,
    .lower_finals .match::after,
    .grand_finals_column .match::before,
    .grand_finals_column .match::after,
    .grand_finals_reset_column .match::before,
    .grand_finals_reset_column .match::after {
      border-left: none;
      border-top-left-radius: 0em;
    }

    /*Hide matches*/

    .hidebracket.match::after {
      position: absolute;
      left: 0;
      z-index: 1;
      content: "";
      display: block;
      width: 1em;
      height: 10%;
      border-left: none;
      top: 50%;
      border-top: none;
      width: 1.5em;
      -webkit-transform: translate(0, -1px);
      transform: translate(0, -1px);
    }

    .hidematch.match::after {
      position: absolute;
      left: 0;
      z-index: 1;
      content: "";
      display: block;
      width: 1em;
      height: 10%;
      border-left: none;
      top: 50%;
      border-top: none;
      width: 1.5em;
      -webkit-transform: translate(0, -1px);
      transform: translate(0, -1px);
    }

    .hidematch {
      visibility: hidden;
    }

    .hidematch.matchWrapper::after {
      position: absolute;
      left: 0;
      z-index: 1;
      content: "";
      display: block;
      width: 1em;
      height: 10%;
      border-left: none;
      top: 50%;
      border-top: none;
      width: 1.5em;
      -webkit-transform: translate(0, -1px);
      transform: translate(0, -1px);

    }

    .hidePreviousUpper.matchWrapper::before,
    .hidePreviousLower.match::after,
    .hideNext.matchWrapper::after {
      display:none;
    }
  }
`;

export default PageWrapper;