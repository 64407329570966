import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  align-self: flex-start;
  background: var(--secondary-background);
  border: .1rem solid var(--border);
  box-shadow: 0px .3rem .6rem rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  max-width: 53rem;
  overflow-x: scroll;
  padding: 3rem;
  width: 100%;
  /* margin: 3rem 0; */
  &.c-SlimStandingsCard {
    margin: 3rem 0;
    overflow-x: hidden;
    @media only screen and (min-width: 1563px) {
      margin: 0 0 3rem;
    }
  }
  @media only screen and (max-width: 768px) {
    margin: 0 auto;
  }
  .standings-header-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    @media only screen and (max-width: 768px) {
      margin: 1rem 0;
    }
  }
  h3 {
    color: var(--text);
    font-family: var(--font-body);
    font-size: 1.8rem;
    font-weight: bold;
  }
  a {
    color: var(--primary);
    font-size: 1.4rem;
    margin-right: 2rem;
    text-decoration: none;
  }
  table {
    box-sizing: border-box;
    font-size: 1.4rem;
    margin-top: 1rem;
    min-width: 35rem;
    width: 100%;
    thead {
      color: var(--text);
      border-bottom: .1rem solid var(--secondary-background);
      th {
        text-align: center;
        padding-bottom: 1rem;
        &:nth-of-type(1) {
          text-align: left;
        }
      }
    }
    tbody {
      color: var(--text);
      font-weight: bold;
      tr {
        cursor: pointer;
        &:not(:last-of-type) {
          border-bottom: .1rem solid var(--secondary-background);
        }
        &.active {
          background-color: var(--secondary-background);
        }
      }
    }
    td {
      box-sizing: border-box;
      text-align: center;
      padding: .75rem 0;
      vertical-align: middle;
      &:nth-of-type(1),
      &:nth-of-type(2) {
        text-align: left;
      }
      &:nth-of-type(2) {
        align-items: center;
        display: flex;
        justify-content: flex-start;
      }
      img {
        margin-right: 1rem;
        max-width: 20px;
      }
      span {
        display: inline-block;
      }
    }
  }
`;