import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  background: var(--border);
  float: right;
  max-width: 25rem;
  @media only screen and (max-width: 768px) {
    float: none;
  }
  select {
    box-sizing: border-box;
    color: var(--text);
    font-family: Archivo, sans-serif;
    font-size: 1.8rem;
    font-weight: bold;
    height: 6rem;
    outline: none;
    padding-left: 1rem;
    position: relative;
    width: 90%;
    margin: 0 1.4rem;
    background: transparent;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    @media only screen and (max-width: 768px) {
      font-size: 1.2rem;
      height: 4rem;
    }
  }
  option {
    display: inline-block;
  }
`;